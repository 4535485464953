@import url("https://fonts.googleapis.com/css?family=Ubuntu&display=swap&subset=cyrillic");

@import "~normalize.css/normalize";

@import "styles/variables";
@import "styles/type";
@import "styles/form";
@import "styles/ui";
@import "styles/btn";

* {
  outline: 0;
}

body {
  font-family: "Ubuntu", sans-serif;
  color: $gray--dark;
}
