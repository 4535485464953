.form {
  &__item {
    margin-bottom: 20px;
  }

  &__label {
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 7px;

    &--no-uppercase {
      text-transform: none;
      font-size: 14px;
    }
  }

  &__error {
    color: $danger;
    font-size: 10px;
    margin-top: 5px;
  }
}
