@import "styles/variables";

h1,
.h1 {
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 1em;
}

h3,
.h3 {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 1em;
  text-transform: uppercase;
}

a {
  text-decoration: none;
  color: $primary-color;

  &:hover {
    text-decoration: underline;
  }
}
