@import "styles/variables";

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  font-size: 30px;
  color: $primary-color;
  text-transform: uppercase;
}

.failure {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  font-size: 30px;
  color: $danger;
  text-transform: uppercase;
}

input[type="text"] {
  width: 100%;
  border: 1px solid $primary-color--light;
  border-radius: $border-radius;
  height: 40px;
  background-color: transparent;
  box-sizing: border-box;
  padding: 0 10px;
}
