// Colors
$primary-color: #00aeea;
$primary-color--light: #cedce5;
$primary-color--lighter: #f3f5f8;

$gray--dark: #1f1f1f;
$gray: #3d3d3d;
$gray--light: #959595;

$black: #000;
$white: #fff;

$danger: #f3292b;

// Border
$border-radius: 4px;
