@import "styles/variables";

.draw-detail {
  &__wrapper {
    display: flex;
    height: 100vh;
  }

  &__filter {
    flex: none;
    width: 25%;
    background-color: $primary-color--lighter;
    border-right: 1px solid $primary-color--light;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    flex-direction: column;
  }

  &__draw-wrapper {
    flex: none;
    width: 75%;
    position: relative;
    padding: 30px;
    overflow: auto;
    box-sizing: border-box;
  }

  &__back-btn {
    color: $gray--light;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    svg {
      margin-right: 11px;
    }
  }

  &__layers-list {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow: auto;
    max-height: 100%;
  }

  &__layers-item {
    margin-bottom: 9px;
    font-size: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    input {
      margin-right: 6px;
    }
  }

  &__pin-name {
    background-color: $white;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;
    margin-bottom: 20px;
    color: $gray--light;
    font-style: italic;

    &--active {
      color: $gray;
    }
  }

  &__pin-name-image {
    margin-top: 10px;
    max-width: 100%;
  }

  &__add-pin-form {
    border: 1px dashed $gray--light;
    border-radius: $border-radius;
    padding: 20px;
  }

  &__processed {
    width: 60%;
    margin: 0 auto;
    margin-top: 74px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__processed-text {
    padding: 20px 0;
    color: $gray--dark;
    font-size: 16px;
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__switcher {
    display: flex;
    margin-bottom: 27px;
    border-bottom: 1px solid $primary-color--light;
  }

  &__switch-btn {
    width: 100%;
    height: 50px;
    background-color: transparent;
    border: 0;
    padding: 0;
    text-transform: uppercase;
    font-size: 16px;
    cursor: pointer;
    color: $primary-color;
    position: relative;

    &--active {
      color: $gray--dark;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: $gray--dark;
        position: absolute;
        bottom: -1px;
        left: 0;
      }
    }
  }
}
