@import "styles/variables";

.btn {
  background-color: $primary-color;
  border: 0;
  border-radius: $border-radius;
  color: $white;
  text-transform: uppercase;
  font-size: 14px;
  height: 50px;
  padding: 0 30px;
  cursor: pointer;

  &--block {
    width: 100%;
    display: block;
  }

  &--link {
    padding: 0;
    height: auto;
    border-radius: 0;
    background-color: transparent;
    color: $primary-color;
    text-transform: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &--cancel {
    padding: 0;
    height: auto;
    border-radius: 0;
    background-color: transparent;
    color: $gray--light;
    text-transform: none;
    text-decoration: underline;
  }
}
